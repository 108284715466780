(function () {
  'use strict';

  angular
    .module('events.guests')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.guests', {
        abstract: true,
        url: '/:eventId/guests',
        templateUrl: 'events/guests/guests.tpl.html',
        resolve: {
          event: function ($stateParams, Restangular) {
            return Restangular.one('events', $stateParams.eventId).get();
          }
        }
      })
    ;
  }
}());
